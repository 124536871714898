import React from 'react'
import { Link } from 'react-router-dom'
import FadeIn from './Component/FadeIn';
import './Lesson.css'

const Lesson: React.FC = () => (

  <div>
    <FadeIn>
      <div className='lessonDetail'>
        <LessonDetailTitle />
        <LessonDetail />
      </div>
    </FadeIn>
    <FadeIn>
      <div className='lessonPrice'>
        <LessonPriceTitle />
        <LessonPrice />
      </div>
    </FadeIn>
  </div>
)

const LessonDetailTitle: React.FC = () => (
  <p className='lessonDetailTitle'>
    <img className='lessonDetailTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="lessonDetailTitleBackground" />
    <img className='lessonDetailTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category2.png'} alt="lessonDetailTitleLeft" />
    レッスン紹介
  </p>
)

const LessonDetail: React.FC = () => (
  <div>
    <p className="lessonText">&emsp;レッスンは年間40回です。子供から大人まで一人一人に合ったレッスンをしています。</p>
    <table>
      <tbody>
        <tr>
          <th>幼稚園生</th>
          <td>ピアノだけではなく歌ったり聴いたりカードを使ってのレッスンです。</td>
        </tr>
        <tr>
          <th>小学生以上</th>
          <td>音楽の基礎を学びながら、バーナムなどの教材を使ったレッスンからスタートします。</td>
        </tr>
        <tr>
          <th>中学生以上</th>
          <td>合唱などの伴奏オーデションにも対応します。</td>
        </tr>
        <tr>
          <th>高校生以上</th>
          <td>クラッシックのスキルアップや自分で作曲やアレンジする等その子が求めるレッスンをします。</td>
        </tr>
        <tr>
          <th>大人</th>
          <td>ピアノ初心者の方から子供のころ弾いてまたやりたいな、あの曲が弾きたい、それぞれ想いは様々ですが楽しく時にはおしゃべりしたりしながらレッスンしています。</td>
        </tr>
      </tbody>
    </table>
    <p className="lessonText">&emsp;無料で30分間の体験レッスンも行っています。体験レッスンのお申し込みは、お問い合わせフォームから！</p>
    <Link to='/contact' className='lessonDetailButton'>お問い合わせはこちら</Link>
  </div>
)

const LessonPriceTitle: React.FC = () => (
  <p className='lessonPriceTitle'>
    <img className='lessonPriceTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="lessonPriceTitleBackground" />
    <img className='lessonPriceTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category2.png'} alt="lessonPriceTitleLeft" />
    月謝
  </p>
)

const LessonPrice: React.FC = () => (
  <div>
    <p className="lessonPriceDetail">&emsp;下の表の中から、ご自身にあったレッスン形式をお選びいただけます。</p>
    <table>
      <tbody>
        <tr>
          <th>幼稚園生</th>
          <td>🎵&emsp;30分&emsp;6,000円~<br />&emsp;&emsp;年間40回レッスン</td>
        </tr>
        <tr>
          <th>小学生 以上</th>
          <td>
            🎵&emsp;30分&emsp;7,000円~<br />&emsp;&emsp; 年間40回レッスン<br />
            🎵&emsp;45分&emsp;8,000円~<br />&emsp;&emsp; 年間40回レッスン
          </td>
        </tr>
        <tr>
          <th>おとな レッスン</th>
          <td>🎵&emsp;45分&emsp;7,000円~<br />&emsp;&emsp;年間36回レッスン</td>
        </tr>
      </tbody>
    </table>
    <p className="lessonPriceDetail">&emsp;1回レッスン 3,000円。その他、60分レッスンや月2回レッスンもあります。</p>
    <p className="lessonPriceDetail">&emsp;レッスンの時間等、気になる点がありましたら、お問い合わせフォームからご質問を受け付けております。</p>
    <Link to='/contact' className='lessonPriceButton'>お問い合わせはこちら</Link>
  </div>
)

export default Lesson