import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';

import { ScreenSizeContext } from './App'
import { TabButtonContext } from './App';

import './PageHeaders.css'

interface HeaderProps {
  text: string;
  link: string;
}

const PageHeaders: React.FC = () => {
  const screenSize = useContext(ScreenSizeContext);
  return (
    <header>
      <HeaderLogo />
      {screenSize.width < 1024 ? <HeaderMenu /> : <HeaderTab />}
    </header>
  )
}

// ロゴのコンポーネント
const HeaderLogo: React.FC = () => (
  <div className='logo'>
    <img className='boardImage' src={process.env.PUBLIC_URL + '/picture/base/board.png'} alt="沼津市原にあるピアノ教室です" />
    <p className='borderText'>沼津市原にあるピアノ教室です</p>
    <img className='logoBackImage' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="タイトル背景" />
    <img className='logoLeftImage' src={process.env.PUBLIC_URL + '/picture/base/left_title.png'} alt="タイトル左" />
    <img className='logoRightImage' src={process.env.PUBLIC_URL + '/picture/base/right_title.png'} alt="タイトル右" />
    <Link to='/' >
      <img className='logoImage' src={process.env.PUBLIC_URL + '/picture/base/Logo.png'} alt="くれっしぇんどピアノ教室" />
    </Link>
  </div>
)

// スマホ用メニュー
const HeaderMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  const headerTabContext = useContext(TabButtonContext);
  return (
    <div className="headerMenu">
      {/* スマホ用メニューボタン */}
      <div className={`headerMenuBackground ${open ? 'open' : ''}`}>
        <button onClick={handleToggle} className={`headerMenuButton ${open ? 'open' : ''}`}>
          <div className='headerMenuButtonLine'></div>
          <div className='headerMenuButtonLine'></div>
          <div className='headerMenuButtonLine'></div>
        </button>
        <ul className={`headerMenuList ${open ? 'open' : ''}`}>
          <li className='headerMenuListButton'><Link onClick={handleToggle} to={headerTabContext.link1}>{headerTabContext.text1}</Link></li>
          <li className='headerMenuListButton'><Link onClick={handleToggle} to={headerTabContext.link2}>{headerTabContext.text2}</Link></li>
          <li className='headerMenuListButton'><Link onClick={handleToggle} to={headerTabContext.link3}>{headerTabContext.text3}</Link></li>
          <li className='headerMenuListButton'><Link onClick={handleToggle} to={headerTabContext.link4 + "/1"}>{headerTabContext.text4}</Link></li>
          <li className='headerMenuListButton'><Link onClick={handleToggle} to={headerTabContext.link5}>{headerTabContext.text5}</Link></li>
          <li className='headerMenuListButton'><Link onClick={handleToggle} to={headerTabContext.link6}>{headerTabContext.text6}</Link></li>
        </ul>
      </div>
      <div className={`headerMenuOuter ${open ? 'open' : ''}`} onClick={handleToggle}></div>
    </div>
  )
}

// タブのコンポーネント
const HeaderTab: React.FC = () => {
  const headerTabContext = useContext(TabButtonContext);
  return (
    <nav>
      <ul className='headerTab'>
        <HeaderTabButton text={headerTabContext.text1} link={headerTabContext.link1} />
        <HeaderTabButton text={headerTabContext.text2} link={headerTabContext.link2} />
        <HeaderTabButton text={headerTabContext.text3} link={headerTabContext.link3} />
        <HeaderTabButton text={headerTabContext.text4} link={headerTabContext.link4 + "/1"} />
        <HeaderTabButton text={headerTabContext.text5} link={headerTabContext.link5} />
        <HeaderTabButton text={headerTabContext.text6} link={headerTabContext.link6} />
      </ul>
    </nav>
  )
}

// タブボタンのコンポーネント
const HeaderTabButton: React.FC<HeaderProps> = ({ text, link }) => (
  <li className='headerTabButton'><Link to={link}>{text}</Link></li>
)

export default PageHeaders