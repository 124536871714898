import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import './PageFotters.css'
import { TabButtonContext, InfoContext } from './App';


interface FotterProps {
  text: string;
  link: string;
}

const PageFotters: React.FC = () => (
  <footer>
    <FotterTabButton />
    <FotterInfotmation />
  </footer>
);

const FotterTabButton: React.FC = () => {
  const tabButtonContext = useContext(TabButtonContext);
  return (
    <div className='fotterTabButton'>
      <FotterButton text={tabButtonContext.text1} link={tabButtonContext.link1} />
      <FotterButton text={tabButtonContext.text2} link={tabButtonContext.link2} />
      <FotterButton text={tabButtonContext.text3} link={tabButtonContext.link3} />
      <FotterButton text={tabButtonContext.text4} link={tabButtonContext.link4 + "/1"} />
      <FotterButton text={tabButtonContext.text5} link={tabButtonContext.link5} />
      <FotterButton text={tabButtonContext.text6} link={tabButtonContext.link6} />
    </div>
  );
};

// ボタンのコンポーネント
const FotterButton: React.FC<FotterProps> = ({ text, link }) => (
  <Link to={link}>{text}</Link>
)

const FotterInfotmation: React.FC = () => {
  const infoContext = useContext(InfoContext);
  return (
    <div>
      <p className='fotterInfomationTitle'>{infoContext.name}</p>
      <div className='fotterContents'>
        <FotterInstagramIntroduction />
      </div>
      <p className='fotterCopyRight'>© 2024 くれっしぇんどピアノ教室. All rights reserved.</p>
    </div>
  );
}

// フッター住所などの表示削除
// const FotterAddress: React.FC = () => {
//   const infoContext = useContext(InfoContext);
//   return (
//     <div className='fotterAddress'>
//       <p>{infoContext.address}</p>
//     </div>
//   );
// }

const FotterInstagramIntroduction: React.FC = () => (
  <a className='footerInstagramIntroduction' href="https://www.instagram.com/cresc.piano?igsh=cmY1a3I5b2psMDBm" target="_blank" rel="noopener noreferrer">
    <FotterInstagramIcon />
    <p>Instagram</p>
  </a>
);

const FotterInstagramIcon: React.FC = () => (
  <img className='footerInstagramIcon' src={process.env.PUBLIC_URL + '/picture/base/instagram_icon.svg'} alt="FotterInstagramIcon" />
);

export default PageFotters
