import React from 'react'
import FadeIn from './Component/FadeIn';
import NewsContents from './Component/NewsContents';
import './News.css';

// お知らせのコンポーネント
const News: React.FC = () => (
    <FadeIn>
        <div className='news'>
            <NewsTitle />
            <NewsContents showAll={true} />
        </div>
    </FadeIn>
);

const NewsTitle: React.FC = () => (
    <p className='newsTitle'>
        <img className='newsTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="newsTitleBackground" />
        <img className='newsTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category3.png'} alt="newsTitleLeft" />
        お知らせ
    </p>
)

export default News

