import React, { useEffect, useState, useContext } from 'react'
import { useSpring, animated } from 'react-spring';

import { ScreenSizeContext, ScreenSizeInterface } from './App'

interface Note {
    // 前回アニメーション時の画面サイズ
    prevScreenWidth: number,
    prevScreenHeight: number,
    // 移動元の座標と回転角度
    currentX: number,   // 画面の左端からの距離
    currentY: number,   // 画面の上端からの距離
    currentRotateZ: number, // 回転角度
    // 移動先の座標と回転角度
    targetX: number,    // 画面の左端からの距離
    targetY: number,    // 画面の上端からの距離
    targetRotateZ: number,  // 回転角度
    // 移動時間
    moveTime: number,   // 移動時間
    // 音符の設定
    imageSize: number,   // 大きさ
    imageOpacity: number,    // 透明度
    imageSrc: string,   // 画像のパス
}

const PageAnimation: React.FC = () => {
    // const screenSize: ScreenSizeInterface = useContext(ScreenSizeContext);
    const noteNumber: number = 19;
    const noteElements: JSX.Element[] = Array(noteNumber).fill(null).map((_, i) => <MoveNote key={i} />);

    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
            <img src={process.env.PUBLIC_URL + '/picture/base/background_image.png'} alt="background_image" style={{ position: 'fixed', width: '100%', height: '100%', objectFit: 'cover', opacity: 0.7 }} />
            {noteElements}
            {/* 画面サイズの確認用 */}
            {/* {screenSize.width + " " + screenSize.height} */}
        </div>
    )
}
// 画像のパスの配列
const imagePaths = [
    '/picture/base/01_tone_kigou.png',
    '/picture/base/02_heon_kigou.png',
    '/picture/base/03_4bunno4byoushi.png',
    '/picture/base/04_sharp.png',
    '/picture/base/05_flat.png',
    '/picture/base/06_natural.png',
    '/picture/base/07_zen_onpu.png',
    '/picture/base/08_2bu_onpu.png',
    '/picture/base/09_2bu_onpu_futen.png',
    '/picture/base/10_4bu_onpu.png',
    '/picture/base/11_8bu_onpu.png',
    '/picture/base/12_8bu_onpu_renkou.png',
    '/picture/base/13_16bu_onpu.png',
    '/picture/base/14_16bu_onpu_renkou.png',
    '/picture/base/15_zen_kyuufu.png',
    '/picture/base/16_2bu_kyuufu.png',
    '/picture/base/17_4bu_kyuufu.png',
    '/picture/base/18_8bu_kyuufu.png',
    '/picture/base/19_16bu_kyuufu.png',
];

let imageIndex = 0;  // 画像のインデックス

const MoveNote: React.FC = () => {
    // 画面サイズを取得
    const screenSize: ScreenSizeInterface = useContext(ScreenSizeContext);
    // 初期値の設定
    const baseDistance: number = 100;   // ランダム値と乗算する値
    const baseMoveTime: number = 1000;   // ランダム値と乗算する値
    const baseRotateZ: number = 40 * Math.random();     // 回転角度
    const baseOpacity: number = 0.5;    // 透明度
    const initX: number = Math.random() * screenSize.width - baseDistance / 2;  // 画面の左端からの距離
    const initY: number = Math.random() * screenSize.height - baseDistance / 2; // 画面の上端からの距離
    // 画像サイズの設定
    const baseImageSize: number = 10;   // ランダム値と乗算する値
    const minImageSize: number = 30;    // 画像サイズの最小値

    // 画像のパスを順番に選択
    const imagePath = imagePaths[imageIndex];

    // 次の画像のインデックスを更新（最後の画像の後は最初に戻る）
    imageIndex = (imageIndex + 1) % imagePaths.length;

    const [note, setNote] = useState<Note>({
        prevScreenWidth: screenSize.width,
        prevScreenHeight: screenSize.height,
        currentX: initX,
        currentY: initY,
        currentRotateZ: baseRotateZ,
        targetX: initX + Math.random() * baseDistance - baseDistance / 2,
        targetY: initY + Math.random() * baseDistance - baseDistance / 2,
        targetRotateZ: - baseRotateZ,
        moveTime: Math.random() * baseMoveTime,
        imageSize: Math.random() * baseImageSize + minImageSize,
        imageOpacity: baseOpacity,
        imageSrc: process.env.PUBLIC_URL + imagePath,
    });

    const props = useSpring({
        loop: true,
        to: { 
            x: note.targetX,
            y: note.targetY,
            rotateZ: note.targetRotateZ
        },
        from: {
            x: note.currentX,
            y: note.currentY,
            rotateZ: note.currentRotateZ
        },
        config: { duration: note.moveTime }, // アニメーションの1周期の長さ
        onStart: () => {
            setNote(prevNote => ({
                ...prevNote,
                currentX: (prevNote.targetX + 1),     // currentXとtargetXが同じ値だとアニメーションが動かないので+1している
                currentY: (prevNote.targetY + 1),     // currentYとtargetYが同じ値だとアニメーションが動かないので+1している
                currentRotateZ: prevNote.targetRotateZ,
                moveTime: Math.random() * baseMoveTime + baseMoveTime,
            }));
        },
        onRest() {
            setNote(prevNote => ({
                ...prevNote,
                targetX: Math.min(Math.max(prevNote.targetX + Math.random() * baseDistance - baseDistance / 2, 0), screenSize.width - note.imageSize * 2),
                targetY: Math.min(Math.max(prevNote.targetY + Math.random() * baseDistance - baseDistance / 2, 0), screenSize.height - note.imageSize * 2),
                targetRotateZ: - prevNote.currentRotateZ,
            }));
        },
    });
    // screenSizeが変化したときに音符の位置を調整します
    useEffect(() => {
        setNote(prevNote => ({
            ...prevNote,
            currentX: (prevNote.currentX / prevNote.prevScreenWidth) * screenSize.width,
            currentY: (prevNote.currentY / prevNote.prevScreenHeight) * screenSize.height,
            targetX: (prevNote.targetX / prevNote.prevScreenWidth) * screenSize.width,
            targetY: (prevNote.targetY / prevNote.prevScreenHeight) * screenSize.height,
            prevScreenWidth: screenSize.width,
            prevScreenHeight: screenSize.height,
        }));
    }, [screenSize.width, screenSize.height]);
    return <animated.img
        src={note.imageSrc}
        alt="note"
        style={{ ...props, position: "absolute", opacity: note.imageOpacity }}
        // width={note.imageSize}  //縦幅依存とする
        height={note.imageSize}
    />;
}

export default PageAnimation
