import React, { useContext } from 'react'
import FadeIn from './Component/FadeIn';
import AccessMap from './Component/AccessMap';
import { InfoContext } from '../App';
import './Access.css'

const Access: React.FC = () => (
  <FadeIn>
    <div className='access'>
      <AccessTitle />
      <AccessMap />
      <SchoolInfo />
    </div>
  </FadeIn>
)

const AccessTitle: React.FC = () => {
  return (
    <p className='accessTitle'>
      <img className='accessTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="accessTitleBackground" />
      <img className='accessTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category4.png'} alt="accessTitleLeft" />
      アクセス
    </p>
  );
}

const SchoolInfo: React.FC = () => {
  const infoContext = useContext(InfoContext);
  return (
    <div className='school-info'>
      <table>
        <tbody>
          <tr>
            <th>教室名</th>
            <td>{infoContext.name}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>{infoContext.address}<br />{infoContext.addressDetail1}<br />{infoContext.addressDetail2}</td>
          </tr>
          <tr>
            <th>代表</th>
            <td>{infoContext.representative}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{infoContext.tel}</td>
          </tr>
          <tr>
            <th>備考</th>
            <td>お問い合わせからのメールは24時間受付しています。</td>
          </tr>
        </tbody>
      </table>
      <p className='classroomImageTitle'>教室全体の写真</p>
      <img className='classroomImage' src={process.env.PUBLIC_URL + '/picture/access/classroom.jpg'} alt="教室内の写真" />
    </div>
  );
}

export default Access
