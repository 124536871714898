import React from 'react'
import { Link } from 'react-router-dom'
import FadeIn from './FadeIn';
import NewsContents from './NewsContents';
import './NewsComponent.css';

const NewsComponent = () => (
    <FadeIn>
        <div className='newsComponent'>
            <NewsComponentTitle />
            <NewsContents showAll={false} />
            <Link to='/news/1' className='allNewsList'>一覧を見る</Link>
        </div>
    </FadeIn>
)

const NewsComponentTitle: React.FC = () => (
    <p className='newsComponentTitle'>
        <img className='newsComponentTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="newsComponentTitleBackground" />
        <img className='newsComponentTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category3.png'} alt="newsComponentTitleLeft" />
        最新情報
    </p>
)

export default NewsComponent

